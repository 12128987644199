<template>
<section class='home_list_preload'>
   <div class="swiper-container">

      <div class="left"></div>
      <div class="right"></div>
      <div class="swiper-wrapper">
         <div
            class="swiper-slide"
            v-for="item in homeNewsList"
            :key="item.id"
            @mouseenter="newsHover"
            @mouseleave="newsLeave"
            @mouseup="toNewsPage(item.title,item.id)"
         >
            <div class="box">
               <span class="tiem_block"></span>
               <img :src="picServeURL+item.image" alt="">
               <div class="mask-b">
                  <span class="title">{{ item.title }}</span>
                  <i :style="`background-image: url(${picServeURL+item.image});`"></i>
                  <div class="enter" @mouseup="toNewsPage(item.title,item.id)">
                     查看新闻
                     <span class="iconfont iconhome_more"></span>
                  </div>
               </div>
            </div>

         </div>
      </div>
   </div>
   <div class="swiper-button-prev iconfont iconhome_left2"></div>
   <div class="swiper-button-next iconfont iconhome_right2"></div>
</section>
</template>

<script>
export default {
   data() {
      return {
         picServeURL: Global.Production,
      }
   },
   props: ["homeNewsList"],
   methods: {
      newsHover(e) {
         e.target.style.top = "-8Px";
         e.target.firstChild.lastChild.lastChild.lastChild.style.marginLeft = "10Px"
      },
      newsLeave(e) {
         e.target.style.top = "0Px";
         e.target.firstChild.lastChild.lastChild.lastChild.style.marginLeft = "0Px"
      },
      toNewsPage(title, id) {
         this.$router.push({
            name: "NewsPage",
            params: {
               id
            },
         });
      },
      swiperDemo() {
         var swiper = new Swiper('.swiper-container', {
            autoplay: false, //自动切换
            speed: 800, //切换速度
            allowTouchMove: false, //触摸滑动
            centeredSlides: false, //默认false slide居左
            spaceBetween: 0, //slide之间设置距离Px  注意：swiper切换的偏移量是基于slider实际的宽度的，设置了此项之后偏移量会有偏差
            slidesOffsetBefore: 0, //slide与左边框的预设偏移量Px
            loop: true, //循环模式
            slidesPerView: 'auto', //设置slider容器能够同时显示的slides数量
            navigation: { //点击切换
               nextEl: '.swiper-button-next',
               prevEl: '.swiper-button-prev',
            },
         });
         window.onresize = function () {
            swiper.update();
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.home_list_preload {
   -moz-user-select: none;
   -webkit-user-select: none;
   user-select: none;
   position: relative;

   .swiper-container {
      overflow: visible;

      .left {
         width: 58Px;
         height: 300Px;
         opacity: .5;
         position: absolute;
         left: 0;
         box-shadow: 50Px 0Px 35Px -25Px #fff inset;
         z-index: 99;
      }

      .right {
         z-index: 99;
         width: 58Px;
         height: 300Px;
         opacity: .5;
         position: absolute;
         right: 0;
         box-shadow: -50Px 0Px 35Px -25Px #fff inset;
      }

      .swiper-wrapper {
         width: 100%;
         height: 300Px;

         .swiper-slide {
            width: 298Px;
            height: 300Px;
            overflow: hidden;
            position: relative;
            transition: all 100ms linear;
            margin-left: 48Px;

            .box {
               width: 298Px;
               height: 300Px;

               .tiem_block {
                  width: 20Px;
                  height: 20Px;
                  background-color: #fff;
               }

               img {
                  height: 100%;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);

               }

               .mask-b {
                  width: 100%;
                  height: 112Px;
                  position: absolute;
                  bottom: 0;
                  z-index: 0;
                  color: #fff;
                  overflow: hidden;

                  &>i {
                     content: '';
                     width: 120%;
                     height: 120%;
                     position: absolute;
                     left: 50%;
                     top: 50%;
                     transform: translate(-50%, -50%);
                     background-color: rgba(255, 255, 255, 0.5);
                     z-index: -1;
                     background-position: center bottom;
                     background-size: cover;
                     -webkit-filter: blur(12Px);
                     -moz-filter: blur(12Px);
                     -ms-filter: blur(12Px);
                     -o-filter: blur(12Px);
                     filter: blur(12Px);
                  }

                  .title {
                     display: inline-block;
                     box-sizing: border-box;
                     margin: 16Px 16Px 10Px;
                     font-size: 16Px;
                     font-weight: 500;
                     line-height: 26Px;
                     white-space: normal;
                     overflow: hidden;
                     text-overflow: ellipsis;
                     -webkit-line-clamp: 2;
                     -webkit-box-orient: vertical;
                     display: -webkit-box;
                  }

                  .enter {
                     position: absolute;
                     bottom: 17Px;
                     width: 100Px;
                     padding-left: 16Px;
                     font-size: 14Px;
                     font-family:   PingFangSC-Regular, PingFang SC;
                     line-height: 17Px;
                     white-space: nowrap;

                     &:hover {
                        cursor: pointer;
                     }

                     .iconfont {
                        position: relative;
                        display: inline-block;
                        height: 17Px;
                        line-height: 18Px;
                        vertical-align: top;
                        transition: all 100ms linear;
                     }
                  }
               }

            }
         }
      }
   }

   .swiper-button-next,
   .swiper-button-prev {
      z-index: 99;
      position: absolute;
      margin: 0 48Px;
      top: calc(50% - 24Px);
      width: 48Px;
      height: 48Px;
      background-color: #fff;
      opacity: .8;
      box-shadow: 0Px 2Px 4Px 0Px rgba($color: #343E4C, $alpha: 0.5);
      border-radius: 50%;
      outline: none;
      font-size: 20Px;
      color: #333;

      &:hover {
         cursor: pointer;
      }

      &::after {
         content: "";
      }
   }
}
</style>
